import {classes} from './AsideLoader.st.css';
import React from 'react';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../constants';

export const AsideLoader = () => {
  const {experiments} = useExperiments();
  const RemoveOpacityGetFunctions = experiments.enabled(SPECS.RemoveOpacityGetFunctions);
  return (
    <div
      key={'aside-third'}
      className={RemoveOpacityGetFunctions ? classes.root : /* istanbul ignore next */ classes.rootOld}></div>
  );
};
