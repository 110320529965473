import React from 'react';
import {IconToggle, Text} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {classes} from './FoldableSummarySection.st.css';
import ErrorIcon from 'wix-ui-icons-common/on-stage/Error';
import ChevronUp from 'wix-ui-icons-common/on-stage/ChevronUp';
import ChevronDown from 'wix-ui-icons-common/on-stage/ChevronDown';

export enum FoldableSummarySectionDataHook {
  root = 'FoldableSummarySectionDataHooks.root',
  content = 'FoldableSummarySectionDataHook.content',
  total = 'FoldableSummarySectionDataHook.total',
  totalWrapper = 'FoldableSummarySectionDataHook.totalWrapper',
  errorIcon = 'FoldableSummarySectionDataHook.errorIcon',
  chevronUp = 'FoldableSummarySectionDataHook.chevronUp',
  chevronDown = 'FoldableSummarySectionDataHook.chevronDown',
}

export interface FoldableSummarySectionProps {
  onClick: () => void;
  isOpen: boolean;
  shouldShowErrorIcon: boolean;
  total: string;
}

export const FoldableSummarySection = ({onClick, isOpen, total, shouldShowErrorIcon}: FoldableSummarySectionProps) => {
  const localeKeys = useLocaleKeys();
  const totalsSectionId = String(Math.random());

  return (
    <button
      type="button"
      data-hook={FoldableSummarySectionDataHook.root}
      className={classes.root}
      onClick={onClick}
      aria-expanded={isOpen}
      aria-label={shouldShowErrorIcon ? localeKeys.checkout.mobile.alertIcon.ariaLabel() : undefined}
      aria-describedby={totalsSectionId}>
      <div className={classes.contentWrapper}>
        {shouldShowErrorIcon && (
          <ErrorIcon data-hook={FoldableSummarySectionDataHook.errorIcon} className={classes.errorIcon} />
        )}
        <Text data-hook={FoldableSummarySectionDataHook.content}>
          {isOpen
            ? localeKeys.checkout.mobile.hideOrderSummary.label()
            : localeKeys.checkout.mobile.showOrderSummary.label()}
        </Text>
        <div className={classes.iconWrapper}>
          <IconToggle
            icon={<ChevronDown size={20} data-hook={FoldableSummarySectionDataHook.chevronDown} />}
            checkedIcon={<ChevronUp size={20} data-hook={FoldableSummarySectionDataHook.chevronUp} />}
            checked={isOpen}
          />
        </div>
      </div>
      <div
        data-hook={FoldableSummarySectionDataHook.totalWrapper}
        className={classes.totalWrapper}
        aria-hidden={true}
        id={totalsSectionId}>
        <Text data-hook={FoldableSummarySectionDataHook.total} className={classes.total}>
          {total}
        </Text>
      </div>
    </button>
  );
};
