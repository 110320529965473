import React, {useEffect} from 'react';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {PaymentRequestView} from './PaymentRequestView';
import {useControllerProps} from '../Widget/ControllerContext';
import {ErrorMessage} from '../constants';

export enum EmptyViewDataHook {
  root = 'EmptyViewDataHook.root',
  view = 'EmptyViewDataHook.view',
}

export const EmptyView = () => {
  const {
    paymentRequestStore: {sendPaymentRequestErrorPageLoadBIEvent},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();

  useEffect(
    () => {
      sendPaymentRequestErrorPageLoadBIEvent(ErrorMessage.NOT_FOUND);
    },
    /* eslint-disable react-hooks/exhaustive-deps */ []
  );

  return (
    <div data-hook={EmptyViewDataHook.root}>
      <PaymentRequestView
        dataHook={EmptyViewDataHook.view}
        title={localeKeys.checkout.payLinks.linkNotFound.title()}
        subtitle={localeKeys.checkout.payLinks.linkNotFound.subtitle()}
      />
    </div>
  );
};
