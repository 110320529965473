import React from 'react';
import {useEnvironment} from '@wix/yoshi-flow-editor';
import {TPAComponentsProvider} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../Widget/ControllerContext';
import {getDirValue} from '../../../domain/utils/getDirValue';
import {Layout} from '../../../common/components/Layout/Layout';
import {Header} from '../Header/Header';
import {Footer} from '../Footer/Footer';
import {Summary} from '../Summary/Summary';
import {PaymentRequestViewLayout} from '../PaymentRequestViewLayout/PaymentRequestViewLayout';
import {PaymentRequestState} from '../../../types/paymentRequestApp.types';
import {PaymentFlow} from '../PaymentFlow/PaymentFlow';

export enum PaymentRequestAppDataHook {
  root = 'PaymentRequestAppDataHook.root',
  footer = 'PaymentRequestAppDataHook.footer',
  header = 'PaymentRequestAppDataHook.header',
  summary = 'PaymentRequestAppDataHook.summary',
}

const PaymentRequestApp = () => {
  const {failedToFetch, isLoading, isOneColumnView, paymentRequestState} = useControllerProps();
  const {isRTL} = useEnvironment();
  const isUnpaid = paymentRequestState === undefined || paymentRequestState === PaymentRequestState.unpaid;

  return (
    <TPAComponentsProvider value={{rtl: isRTL}}>
      {isUnpaid ? (
        <Layout
          dataHook={PaymentRequestAppDataHook.root}
          isLoading={isLoading}
          failedToFetch={failedToFetch}
          isOneColumnView={isOneColumnView}
          dir={getDirValue(isRTL)}
          Header={() => <Header dataHook={PaymentRequestAppDataHook.header} />}
          Aside={() => <Summary dataHook={PaymentRequestAppDataHook.summary} />}
          Footer={() => <Footer dataHook={PaymentRequestAppDataHook.footer} />}>
          {<PaymentFlow />}
        </Layout>
      ) : (
        <PaymentRequestViewLayout dir={getDirValue(isRTL)} isLoading={isLoading} />
      )}
    </TPAComponentsProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default PaymentRequestApp;
