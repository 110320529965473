import React, {useEffect, useState} from 'react';
import {classes} from './ItemOptions.st.css';
import {Text, TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars';
import ChevronDown from 'wix-ui-icons-common/on-stage/ChevronDown';
import ChevronUp from 'wix-ui-icons-common/on-stage/ChevronUp';

export enum OptionsDataHook {
  collapseExpandButton = 'OptionsDataHook.collapseExpandButton',
}

export interface OptionsProps {
  dataHook: string;
  itemId: number | string;
  options: {title: string; value: string}[];
  maxOptionValLength: number;
  collapseSettings: {
    numberOfOptionsToCollapseAfter: number;
    showMoreTitle: string;
    showLessTitle: string;
  };
}

export const ItemOptions = ({itemId, options, maxOptionValLength, collapseSettings, dataHook}: OptionsProps) => {
  const {numberOfOptionsToCollapseAfter, showMoreTitle, showLessTitle} = collapseSettings;

  const [shouldCollapseAndExpand] = useState(
    Boolean(numberOfOptionsToCollapseAfter && options.length > numberOfOptionsToCollapseAfter)
  );
  const [numberOfOptionsToShow, setNumberOfOptionsToShow] = useState(
    shouldCollapseAndExpand ? numberOfOptionsToCollapseAfter : options.length
  );
  const [isCollapsed, setIsCollapsed] = useState(shouldCollapseAndExpand);

  useEffect(() => {
    setNumberOfOptionsToShow(isCollapsed ? numberOfOptionsToCollapseAfter : options.length);
  }, [isCollapsed, numberOfOptionsToCollapseAfter, options]);

  const getValue = (value: string) => {
    if (value?.length <= maxOptionValLength) {
      return value;
    }
    return `${value.slice(0, maxOptionValLength - 3)}...`;
  };

  const renderOption = (option: {title: string; value: string}) => {
    const {title, value} = option;
    const trimmedOptionTitle = title?.trim();

    if (!trimmedOptionTitle) {
      return (
        <li key={`option-item-${itemId}-value-${value}`}>
          <Text className={classes.option}>{getValue(value)}</Text>
        </li>
      );
    }
    return (
      <li key={`option-item-${itemId}-value-${title}`}>
        <Text className={classes.option}>
          {title}: {getValue(value)}
        </Text>
      </li>
    );
  };

  const renderCollapseExpandButton = () => {
    return (
      <TextButton
        className={classes.collapseButton}
        suffixIcon={isCollapsed ? <ChevronDown /> : <ChevronUp />}
        priority={TextButtonPriority.secondary}
        data-hook={OptionsDataHook.collapseExpandButton}
        onClick={() => setIsCollapsed(!isCollapsed)}>
        {isCollapsed ? showMoreTitle : showLessTitle}
      </TextButton>
    );
  };

  return (
    <div className={classes.root}>
      <ul className={classes.options} data-hook={dataHook}>
        {options.slice(0, numberOfOptionsToShow).map(renderOption)}
      </ul>
      {shouldCollapseAndExpand && renderCollapseExpandButton()}
    </div>
  );
};
