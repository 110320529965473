import React from 'react';
import {classes} from './Summary.st.css';
import {SecureCheckout} from '../../../common/components/SecureCheckout/SecureCheckout';
import {PaymentRequestTotals} from './PaymentRequestTotals/PaymentRequestTotals';
import {PaymentRequestItemSection} from './PaymentRequestItemSection/PaymentRequestItemSection';
import {useControllerProps} from '../Widget/ControllerContext';
import {PaymentRequestMobileSummary} from './PaymentRequestMobileSummary';

export enum SummaryDataHooks {
  root = 'SummaryDataHooks.root',
}

export interface SummaryProps {
  dataHook: string;
}

export const Summary = ({dataHook}: SummaryProps) => {
  const {
    isOneColumnView,
    paymentRequestStore: {paymentRequest},
  } = useControllerProps();

  return (
    <div className={isOneColumnView ? classes.sticky : classes.root} data-hook={dataHook}>
      {isOneColumnView ? (
        <PaymentRequestMobileSummary total={paymentRequest.total.formattedAmount} />
      ) : (
        <>
          <div data-hook={SummaryDataHooks.root} className={classes.summary}>
            <PaymentRequestItemSection />
            <PaymentRequestTotals total={paymentRequest.total.formattedAmount} />
          </div>
          <SecureCheckout />
        </>
      )}
    </div>
  );
};
