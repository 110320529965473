import React from 'react';
import {Text, TextTypography} from 'wix-ui-tpa/cssVars';
import {classes} from './PaymentRequestView.st.css';

export enum PaymentRequestViewDataHooks {
  title = 'PaymentRequestViewDataHooks.title',
  subtitle = 'PaymentRequestViewDataHooks.subtitle',
}

export interface PaymentRequestViewProps {
  dataHook: string;
  title: string;
  subtitle: string;
}

export const PaymentRequestView = ({dataHook, title, subtitle}: PaymentRequestViewProps) => {
  return (
    <div data-hook={dataHook} className={classes.root}>
      <div>
        <Text
          data-hook={PaymentRequestViewDataHooks.title}
          typography={TextTypography.largeTitle}
          className={classes.title}>
          {title}
        </Text>
      </div>
      <Text data-hook={PaymentRequestViewDataHooks.subtitle} className={classes.subtitle}>
        {subtitle}
      </Text>
    </div>
  );
};
