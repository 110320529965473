import React from 'react';
import {classes} from './PaymentPolicyDescription.st.css';
import {PaymentPolicyModel} from '../../../domain/models/common/PaymentPolicy.model';

export enum PaymentPolicyDescriptionDataHook {
  root = 'PaymentPolicyDescription.root',
  subtitle = 'PaymentPolicyDescription.subtitle',
  policy = 'PaymentPolicyDescription.policy',
}

export const PaymentPolicyDescription = ({
  paymentPolicies,
}: {
  paymentPolicies: PaymentPolicyModel[];
}): React.ReactNode => {
  return (
    <div data-hook={PaymentPolicyDescriptionDataHook.root} className={classes.policies}>
      {paymentPolicies.map(
        ({subtitle, policy}, index) =>
          policy && (
            <div key={index}>
              <div data-hook={PaymentPolicyDescriptionDataHook.subtitle} className={classes.productName}>
                {subtitle}
              </div>
              <div data-hook={PaymentPolicyDescriptionDataHook.policy} className={classes.policy}>
                {policy}
              </div>
            </div>
          )
      )}
    </div>
  );
};
