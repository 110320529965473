import React, {ComponentType, createContext, useContext, useState} from 'react';
import {ICashierPaymentsApi} from '@wix/cashier-payments-widget';

export type PaymentsApiContextType = {
  paymentsApi?: ICashierPaymentsApi;
  setPaymentsApi?: (paymentsApi: ICashierPaymentsApi) => void;
  activePaymentId?: string;
  setActivePaymentId: (activePaymentId: string) => void;
};

export const PaymentsApiContext = createContext({} as PaymentsApiContextType);

export function withPaymentsApi<T extends object>(Component: ComponentType<T>) {
  return function Wrapper(props: T) {
    const [paymentsApi, setPaymentsApi] = useState<ICashierPaymentsApi>();
    const [activePaymentId, setActivePaymentId] = useState<string>();
    return (
      <PaymentsApiContext.Provider value={{paymentsApi, setPaymentsApi, activePaymentId, setActivePaymentId}}>
        <Component {...props} />
      </PaymentsApiContext.Provider>
    );
  };
}

export function usePaymentsApi() {
  const {paymentsApi, setPaymentsApi, activePaymentId, setActivePaymentId} = useContext(PaymentsApiContext);
  return {paymentsApi, setPaymentsApi, activePaymentId, setActivePaymentId};
}
