import React from 'react';
import {classes, st} from './PaymentRequestItemSection.st.css';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useEnvironment} from '@wix/yoshi-flow-editor';
import {PaymentRequestItem} from '../PaymentRequestItem/PaymentRequestItem';

export enum PaymentRequestItemSectionDataHooks {
  root = 'PaymentRequestItemSectionDataHooks.root',
}

export const PaymentRequestItemSection = () => {
  const localeKeys = useLocaleKeys();
  const {isMobile} = useEnvironment();

  return (
    <section
      data-hook={PaymentRequestItemSectionDataHooks.root}
      aria-label={localeKeys.checkout.orderSummary.fullSection.ariaLabel()}>
      <ul className={st(classes.itemsList, isMobile ? classes.itemsListMobile : undefined)}>
        <PaymentRequestItem useLayoutForLongPrice={false} />
      </ul>
    </section>
  );
};
