import React, {Children, ReactElement} from 'react';
import {TotalsRow} from '../TotalsRow/TotalsRow';
import {classes} from './TotalsSection.st.css';
import {Text} from 'wix-ui-tpa/cssVars';
import {TotalsDataHook} from '../../Totals';

export interface TotalsSectionProps {
  children?: (ReactElement | false)[] | ReactElement | false;
  title?: string;
  dataHook?: string;
}

export const TotalsSection = ({children, title, dataHook}: TotalsSectionProps) => {
  const flattenedChildren = (Children.toArray(children) as unknown as ReactElement[]).reduce(
    (accumulator: ReactElement[], currentValue: ReactElement) => accumulator.concat(currentValue),
    []
  );
  const validChildren = flattenedChildren.filter(
    (child) => child?.type && (child.type as React.ComponentType<any>).displayName === TotalsRow.displayName
  );

  return (
    <div data-hook={dataHook ?? /* istanbul ignore next */ TotalsDataHook.TotalsSection} className={classes.root}>
      {title && (
        <Text data-hook={TotalsDataHook.TotalsSectionTitle} className={classes.sectionTitle}>
          {title}
        </Text>
      )}
      {validChildren}
    </div>
  );
};

TotalsSection.displayName = 'Totals.TotalsSection';
