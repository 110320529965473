import * as React from 'react';
import {classes} from './ItemName.st.css';
import {Text} from 'wix-ui-tpa/cssVars';

export interface NameProps {
  name: string;
  dataHook: string;
}

export class ItemName extends React.PureComponent<NameProps> {
  public render() {
    const {name, dataHook} = this.props;
    return (
      <Text data-hook={dataHook} className={classes.name}>
        {name}
      </Text>
    );
  }
}
