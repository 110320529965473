import React, {useEffect} from 'react';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {PaymentRequestView} from '../PaymentRequestView';
import {useControllerProps} from '../../Widget/ControllerContext';
import {ErrorMessage} from '../../constants';
import {Button} from 'wix-ui-tpa';
import {classes} from './RedirectErrorView.st.css';

export enum RedirectErrorViewDataHook {
  root = 'RedirectErrorViewDataHook.root',
  view = 'RedirectErrorViewDataHook.view',
  button = 'RedirectErrorViewDataHook.button',
}

export const RedirectErrorView = () => {
  const {
    paymentRequestStore: {sendPaymentRequestErrorPageLoadBIEvent, sendClickOnRedirectErrorButtonBIEvent},
    navigationStore: {navigateBackToPaymentRequest, paymentMethodName},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();

  const handleOnClick = () => {
    sendClickOnRedirectErrorButtonBIEvent();
    navigateBackToPaymentRequest();
  };

  useEffect(
    () => {
      sendPaymentRequestErrorPageLoadBIEvent(`${ErrorMessage.REDIRECT_ERROR} ${paymentMethodName}`);
    },
    /* eslint-disable react-hooks/exhaustive-deps */ []
  );

  return (
    <div className={classes.container} data-hook={RedirectErrorViewDataHook.root}>
      <PaymentRequestView
        dataHook={RedirectErrorViewDataHook.view}
        title={localeKeys.checkout.payLinks.couldntProcessPayment.title()}
        subtitle={localeKeys.checkout.payLinks.couldntProcessPayment.subtitle()}
      />
      <Button className={classes.button} data-hook={RedirectErrorViewDataHook.button} onClick={handleOnClick}>
        {localeKeys.checkout.payLinks.couldntProcessPayment.cta()}
      </Button>
    </div>
  );
};
