import React from 'react';
import {classes} from './PaymentRequestItem.st.css';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {ItemLayout} from '../../../../common/components/Item/ItemLayout';
import {ItemName} from '../../../../common/components/Item/ItemName/ItemName';
import {ItemPrice} from '../../../../common/components/Item/ItemPrice/ItemPrice';
import {ItemOptions} from '../../../../common/components/Item/ItemOptions/ItemOptions';
import {MAX_ITEM_OPTION_LENGTH, NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE} from '../../../../common/constants';
import {ItemImage, Thumbnail} from '../../../../common/components/Item/ItemImage/ItemImage';
import {useControllerProps} from '../../Widget/ControllerContext';

export enum PaymentRequestItemDataHooks {
  LineItemWrapper = 'PaymentRequestItemDataHooks.LineItemWrapper',
  title = 'PaymentRequestItemDataHooks.title',
  Price = 'PaymentRequestItemDataHooks.Price',
  description = 'PaymentRequestItemDataHooks.description',
  Image = 'PaymentRequestItemDataHooks.Image',
  DefaultImage = 'PaymentRequestItemDataHooks.DefaultImage',
}

export const PaymentRequestItem = ({useLayoutForLongPrice}: {useLayoutForLongPrice: boolean}) => {
  const localeKeys = useLocaleKeys();
  const {
    paymentRequestStore: {paymentRequest},
  } = useControllerProps();
  const thumbnail: Thumbnail | undefined = paymentRequest.image
    ? {
        src: paymentRequest.image.src,
        height: paymentRequest.image.height,
        width: paymentRequest.image.width,
        altText: paymentRequest.image.altText,
      }
    : undefined;

  return (
    <li data-hook={PaymentRequestItemDataHooks.LineItemWrapper}>
      <ItemLayout
        descriptionWithColumns={!useLayoutForLongPrice}
        Icon={
          <ItemImage
            defaultImageDataHook={PaymentRequestItemDataHooks.DefaultImage}
            thumbnail={thumbnail}
            imageDataHook={PaymentRequestItemDataHooks.Image}
          />
        }
        MainDescription={<ItemName name={paymentRequest.title} dataHook={PaymentRequestItemDataHooks.title} />}
        SecondaryDescription={
          <ItemPrice
            priceDataHook={PaymentRequestItemDataHooks.Price}
            shortPrice={!useLayoutForLongPrice}
            formattedPrice={paymentRequest.total.formattedAmount}
            priceAriaLabel={localeKeys.checkout.orderSummary.price.ariaLabel({
              price: paymentRequest.total.formattedAmount,
            })}
          />
        }
        Details={
          <div className={classes.itemData}>
            <ItemOptions
              dataHook={PaymentRequestItemDataHooks.description}
              itemId={0}
              options={[{title: '', value: paymentRequest.description}]}
              maxOptionValLength={MAX_ITEM_OPTION_LENGTH}
              collapseSettings={{
                numberOfOptionsToCollapseAfter: NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE + 1,
                showMoreTitle: localeKeys.checkout.order_summary.items.more_details(),
                showLessTitle: localeKeys.checkout.order_summary.items.lessDetails(),
              }}
            />
          </div>
        }
      />
    </li>
  );
};
