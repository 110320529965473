import React from 'react';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {classes} from './InnerFooter.st.css';
import {
  PolicyButtonWithDialog,
  PolicyButtonLocation,
  PolicyType,
} from '../PolicyButtonWithDialog/PolicyButtonWithDialog';
import {ValueEnabledModel} from '../../../domain/models/common/ValueEnabled.model';
import {ValueEnabledTitleModel} from '../../../domain/models/common/ValueEnabledTitle.model';
import {PaymentPolicyModel} from '../../../domain/models/common/PaymentPolicy.model';
import {PaymentPolicyDescription} from '../PaymentPolicyDescription/PaymentPolicyDescription';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../constants';

export interface InnerFooterProps {
  termsAndConditions: ValueEnabledModel;
  privacyPolicy: ValueEnabledModel;
  returnPolicy: ValueEnabledModel;
  digitalPolicy: ValueEnabledModel;
  contactUs: ValueEnabledModel;
  customPolicy: ValueEnabledTitleModel;
  shouldShowDigitalItemPolicy: boolean;
  paymentPolicies?: PaymentPolicyModel[];
  onPolicyClicked: (location: PolicyButtonLocation, policyType: PolicyType) => void;
}

export enum InnerFooterDataHooks {
  termsAndConditionsButton = 'InnerFooterDataHooks.termsAndConditionsButton',
  privacyPolicyButton = 'InnerFooterDataHooks.privacyPolicyButton',
  paymentPolicyButton = 'InnerFooterDataHooks.paymentPolicyButton',
  returnPolicyButton = 'InnerFooterDataHooks.returnPolicyButton',
  digitalItemPolicyButton = 'InnerFooterDataHooks.digitalItemPolicyButton',
  contactUsButton = 'InnerFooterDataHooks.contactUsButton',
  customPolicyButton = 'InnerFooterDataHooks.customPolicyButton',
}

export const InnerFooter = ({
  termsAndConditions,
  privacyPolicy,
  returnPolicy,
  digitalPolicy,
  contactUs,
  customPolicy,
  shouldShowDigitalItemPolicy,
  paymentPolicies,
  onPolicyClicked,
}: InnerFooterProps) => {
  const localeKeys = useLocaleKeys();
  const {experiments} = useExperiments();
  const fixCustomPolicyLongText = experiments.enabled(SPECS.FixCustomPolicyLongText);

  return (
    <div className={classes.footerInner}>
      <PolicyButtonWithDialog
        className={classes.footerButton}
        dataHook={InnerFooterDataHooks.termsAndConditionsButton}
        show={termsAndConditions.enabled}
        dialogTitle={localeKeys.checkout.terms_and_condition_modal.title()}
        location={PolicyButtonLocation.FOOTER}
        policyType={PolicyType.TermsAndConditions}
        onPolicyClicked={onPolicyClicked}
        dialogDescription={() => termsAndConditions.value}>
        {localeKeys.checkout.footer.terms_and_condition_button_text()}
      </PolicyButtonWithDialog>
      <PolicyButtonWithDialog
        className={classes.footerButton}
        dataHook={InnerFooterDataHooks.privacyPolicyButton}
        show={privacyPolicy.enabled}
        dialogTitle={localeKeys.checkout.privacy_policy_modal.title()}
        location={PolicyButtonLocation.FOOTER}
        policyType={PolicyType.PrivacyPolicy}
        onPolicyClicked={onPolicyClicked}
        dialogDescription={() => privacyPolicy.value}>
        {localeKeys.checkout.footer.privacy_policy_button_text()}
      </PolicyButtonWithDialog>
      <PolicyButtonWithDialog
        className={classes.footerButton}
        dataHook={InnerFooterDataHooks.returnPolicyButton}
        show={returnPolicy.enabled}
        dialogTitle={localeKeys.checkout.refund_cancellation_modal.title()}
        location={PolicyButtonLocation.FOOTER}
        policyType={PolicyType.ReturnPolicy}
        onPolicyClicked={onPolicyClicked}
        dialogDescription={() => returnPolicy.value}>
        {localeKeys.checkout.footer.refund_cancellation_button_text()}
      </PolicyButtonWithDialog>
      <PolicyButtonWithDialog
        className={classes.footerButton}
        dataHook={InnerFooterDataHooks.digitalItemPolicyButton}
        show={digitalPolicy.enabled && shouldShowDigitalItemPolicy}
        dialogTitle={localeKeys.checkout.digital_item_policy_modal.title()}
        location={PolicyButtonLocation.FOOTER}
        policyType={PolicyType.DigitalItemPolicy}
        onPolicyClicked={onPolicyClicked}
        dialogDescription={() => digitalPolicy.value}>
        {localeKeys.checkout.footer.digital_item_policy_button_text()}
      </PolicyButtonWithDialog>
      {paymentPolicies && (
        <PolicyButtonWithDialog
          className={classes.footerButton}
          dataHook={InnerFooterDataHooks.paymentPolicyButton}
          show={paymentPolicies.length > 0}
          dialogTitle={localeKeys.checkout.payment_policy_modal.title()}
          location={PolicyButtonLocation.FOOTER}
          policyType={PolicyType.PaymentPolicy}
          onPolicyClicked={onPolicyClicked}
          dialogDescription={() => <PaymentPolicyDescription paymentPolicies={paymentPolicies} />}>
          {localeKeys.checkout.footer.payment_policy_button_text()}
        </PolicyButtonWithDialog>
      )}
      <PolicyButtonWithDialog
        className={classes.footerButton}
        dataHook={InnerFooterDataHooks.contactUsButton}
        show={contactUs.enabled}
        dialogTitle={localeKeys.checkout.contact_us_modal.title()}
        location={PolicyButtonLocation.FOOTER}
        policyType={PolicyType.ContactUs}
        onPolicyClicked={onPolicyClicked}
        dialogDescription={() => contactUs.value}>
        {localeKeys.checkout.footer.contact_us_button_text()}
      </PolicyButtonWithDialog>
      <PolicyButtonWithDialog
        className={classes.footerButton}
        dataHook={InnerFooterDataHooks.customPolicyButton}
        show={customPolicy.enabled}
        dialogTitle={customPolicy.title}
        location={PolicyButtonLocation.FOOTER}
        policyType={PolicyType.CustomPolicy}
        onPolicyClicked={onPolicyClicked}
        dialogDescription={() => customPolicy.value}
        fixCustomPolicyLongText={fixCustomPolicyLongText}>
        {customPolicy.title}
      </PolicyButtonWithDialog>
    </div>
  );
};
