import {classes} from './HeaderLoader.st.css';
import React from 'react';

export const HeaderLoader = () => {
  return (
    <header className={classes.header}>
      <div className={classes.frame}>
        <div key={'header-main'} className={classes.main} />
      </div>
      <div className={classes.divider} />
    </header>
  );
};
