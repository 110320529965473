import {classes, st} from './ItemLayout.st.css';
import React, {ReactNode} from 'react';

export interface LayoutProps {
  descriptionWithColumns: boolean;
  Icon: ReactNode;
  MainDescription: ReactNode;
  SecondaryDescription: ReactNode;
  Details: ReactNode;
  AdditionalInfo?: ReactNode;
}

export const ItemLayout = ({
  descriptionWithColumns,
  Icon,
  MainDescription,
  SecondaryDescription,
  Details,
  AdditionalInfo,
}: LayoutProps) => {
  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <div className={classes.icon}>{Icon}</div>
        <div className={classes.content}>
          <div
            className={st(
              classes.description,
              descriptionWithColumns ? classes.descriptionWithColumns : classes.descriptionWithRows
            )}>
            <div className={classes.mainDescription}>{MainDescription}</div>
            <div
              className={
                descriptionWithColumns
                  ? st(classes.secondaryDescription, classes.shortDescription)
                  : classes.secondaryDescription
              }>
              {SecondaryDescription}
            </div>
          </div>
          <div className={classes.details}>{Details}</div>
        </div>
      </div>
      {AdditionalInfo}
    </div>
  );
};
